import React, {Component} from "react"
import {Button} from "antd"

import {ImageUpload} from "./ImageUpload"
import { InteractiveDropDown } from "./InteractiveDropDown";
import { firestore } from "./firestore";
import { EditableInput } from "./EditableInput"
import { Label } from "./Label"
import {ImageGridView} from "./ImageGridView.js"

class DataUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addingObject: false, 
            objectName: "", 

            classifications: [],
            pendingClassification: "", 
            classification: "",

            cultures: [],
            pendingCulture: "", 
            culture: "", 

            collections: [],
            pendingCollection: "", 
            collection: "", 

            materials: [],
            pendingMaterial: "", 
            objectMaterial: "",

            urls: [],
            idNumber: "",

            objectSize: "", 
            objectWeight: "", 
            objectDate: "", 
            provenance: "", 
            analyses: "", 
            research: "", 
            sources: "", 
            thesaurus: "",
        
        }

        this.uploadObject = this.uploadObject.bind(this);
        this.uploadClassification = this.uploadClassification.bind(this);
        this.uploadCulture = this.uploadCulture.bind(this);
        this.uploadCollection = this.uploadCollection.bind(this);
        this.uploadMaterial = this.uploadMaterial.bind(this);

        // We listen for live changes to our objects collection in Firebase
        firestore.collection("classifications").onSnapshot(snapshot => {
            let classifications = [];
            snapshot.forEach(doc => {            
                const obj = doc.data();
                obj.id = doc.id;
                let classification = obj.classification;
                // console.log("classification: ", obj.classifications)
                classifications.push(classification);  
            
                classifications.sort(function(a, b) {
                    return ( a.localeCompare(b) );
                });
            });
            // Sort our classifications based on time added
            // classifications.sort(function(a, b) {
            //     return (
            //         a.classification < b.classification
            //     );
            // });
            // Anytime the state of our database changes, we update state

            this.setState({ classifications }, () => {
                console.log("Classification added: ")
                this.state.classifications.forEach(function(entry){
                    console.log("Class: ", entry)
                })
            });
        });
        
        firestore.collection("cultures").onSnapshot(snapshot => {
            let cultures = [];
            snapshot.forEach(doc => {            
                const obj = doc.data();
                obj.id = doc.id;
                let culture = obj.culture;
                cultures.push(culture);  
                cultures.sort(function(a, b) {
                    return ( a.localeCompare(b) );
                });
            });
        
            this.setState({ cultures }, () => {
                this.state.cultures.forEach(function(entry){
                    console.log("Received new Culture: ", entry)
                })
            });
        });

        firestore.collection("collections").onSnapshot(snapshot => {
            let collections = [];
            snapshot.forEach(doc => {            
                const obj = doc.data();
                obj.id = doc.id;
                let collection = obj.collection;
                collections.push(collection);  
                collections.sort(function(a, b) {
                    return ( a.localeCompare(b) );
                });
            });
            
            this.setState({ collections }, () => {
                this.state.collections.forEach(function(entry){
                    console.log("Received new Collection: ", entry)
                })
            });  
        }); 

        firestore.collection("materials").onSnapshot(snapshot => {
            let materials = [];
            snapshot.forEach(doc => {            
                const obj = doc.data();
                obj.id = doc.id;
                let material = obj.material;
                materials.push(material);  
                materials.sort(function(a, b) {
                    return ( a.localeCompare(b) );
                });
            });
            
            this.setState({ materials }, () => {
                this.state.materials.forEach(function(entry){
                    console.log("Received new Material: ", entry)
                })
            });  
        }); 
    }

    async uploadObject() {
        console.log("Upload object")

        this.setState({ addingObject: true });
        
        await firestore.collection("objects").add({
            objectName: this.state.objectName,
            urls: this.state.urls,
            classification: this.state.classification,
            culture: this.state.culture,
            idNumber: this.state.idNumber,
            collection: this.state.collection, 
            objectSize: this.state.objectSize, 
            objectWeight: this.state.objectWeight, 
            objectMaterial: this.state.objectMaterial,
            objectDate: this.state.objectDate,
            provenance: this.state.provenance, 
            analyses: this.state.analyses, 
            research: this.state.research, 
            sources: this.state.sources,
            thesaurus: this.state.thesaurus,

            createdAt: new Date().toDateString()
        });

        this.setState({ 
            addingObject: false
          , objectName: ""
          , urls: []
          , classification: ""
          , culture: ""
          , idNumber: ""
          , collection: ""
          , objectSize: ""
          , objectMaterial: ""
          , objectWeight: ""
          , objectDate: ""
          , provenance: ""
          , analyses: ""
          , research: ""
          , sources: ""
          , thesaurus: ""
        }, () => {
              console.log("Object UPLOADED:")
              this.printState()
          });
    }

    async uploadClassification() {
        console.log("upload classification")
        if (!this.state.pendingClassification) return;

        console.log("pending classification")

        this.setState({ addingClassification: true });
        // Add a new todo from the value of the input
        
        await firestore.collection("classifications").add({
            classification: this.state.pendingClassification,
        });
        // Remove the loading flag and clear the input
        this.setState({ addingClassification: false, pendingClassification: ""});
        console.log("classification uploaded")
    }

    async uploadCulture() {
        console.log("upload culture")
        if (!this.state.pendingCulture) return;

        console.log("pending culture")

        this.setState({ addingClassification: true });
        // Add a new todo from the value of the input
        
        await firestore.collection("cultures").add({
            culture: this.state.pendingCulture,
        });
        // Remove the loading flag and clear the input
        this.setState({ addingClassification: false, pendingCulture: ""});
        console.log("culture uploaded")
    }

    async uploadCollection() {
        console.log("upload collection")
        if (!this.state.pendingCollection) return;

        this.setState({ addingClassification: true });
        // Add a new todo from the value of the input
        
        await firestore.collection("collections").add({
            collection: this.state.pendingCollection,
        });
        // Remove the loading flag and clear the input
        this.setState({ addingClassification: false, pendingCollection: ""});
        console.log("collection uploaded")
    }

    async uploadMaterial() {
        console.log("upload material")
        if (!this.state.pendingMaterial) return;

        this.setState({ addingMaterial: true });
        // Add a new todo from the value of the input
        
        await firestore.collection("materials").add({
            material: this.state.pendingMaterial,
        });
        // Remove the loading flag and clear the input
        this.setState({ addingMaterial: false, pendingMaterial: ""});
        console.log("new material uploaded")
    }

    addClassification = (classification) => {
        this.setState({pendingClassification: classification}, () => {
            console.log("PendingClassifcation added")
            this.uploadClassification()

        })
    }

    addCulture = (culture) => {
        this.setState({pendingCulture: culture}, () => {
            this.uploadCulture()
        })
    }

    addCollection = (collection) => {
        this.setState({pendingCollection: collection}, () => {
            this.uploadCollection()
        })
    }

    addMaterial = (material) => {
        this.setState({pendingMaterial: material}, () => {
            this.uploadMaterial()
        })
    }

    addUrl = (newUrl) => {
        this.setState({urls:[...this.state.urls, newUrl]}, () => {
            console.log("Urls: ", this.state.urls)
        })
    }

    addImageName = (imgName) => {
        if (this.objectName === "") {
            this.setState({objectName: imgName})            
        }
    }

    resetState = () => {

    }

    printState = () => {
        console.log("this.state.objectName: ", this.state.objectName) 
        console.log("this.state.classification", this.state.classification)       
        console.log("this.state.culture", this.state.culture)
        console.log("this.state.idNumber", this.state.idNumber)
        console.log("this.state.objectSize", this.state.objectSize)
        console.log("this.state.objectMaterial", this.state.objectMaterial)
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, ()=>{
            console.log("Input: " + [name] + ", value: " + value)
            console.log("State: ")
            this.printState()
        });
    }

    removeImage = (index) => {
        const url = this.state.urls[index];
        const name = this.state.imageNames[index];
        console.log("Remove: ", name);
    }

    async removeUrl(index) {
        console.log("upload material")

        this.setState({ addingMaterial: true });
        // Add a new todo from the value of the input
        
        await firestore.collection("urls").remove .add({
            material: this.state.pendingMaterial,
        });
        // Remove the loading flag and clear the input
        this.setState({ addingMaterial: false, pendingMaterial: ""});
        console.log("new material uploaded")
    }

    render() {
        return (
           <div>
                <form className="data-upload-grid">                   
                    <div className="data-input-fields-grid">
                        <div className="label-input">
                            <Label
                                displayName = "Object"
                            />
                        </div>
                        <div className="text-input">
                             <EditableInput 
                                name = "objectName"
                                value = {this.state.objectName}
                                onChange = {this.handleInputChange}
                            />
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Classification"
                                isOptional = {true}
                            />
                        </div> 
                        <div className="text-input">
                            <InteractiveDropDown className = "App-input-field"
                                    size = "large"
                                    name = "Classification"
                                    isOptional = {true}
                                    addClassification = {this.addClassification}
                                    value = {this.state.classification}
                                    items = {this.state.classifications} 
                                    newValueSelected = {(value) => {
                                        this.setState({classification: value}, () => {console.log("classifaction: ", value)})}}
                                    required /> 
                        </div> 
                        <div className="label-input">
                            <Label
                                displayName = "Culture"
                                isOptional = {true}
                            />
                        </div> 
                        <div className="text-input">
                            <InteractiveDropDown className = "App-input-field"
                                size = "large"
                                name = "Culture"
                                isOptional = {true}
                                value = {this.state.culture}
                                addClassification = {this.addCulture}
                                items = {this.state.cultures} 
                                newValueSelected = {(value) => { this.setState({culture: value})}}
                                require/>
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "ID-Number"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                name = "idNumber"
                                value = {this.state.idNumber}
                                onChange = {this.handleInputChange}
                            />
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Collection"
                            />
                        </div>         
                        <div className="text-input">
                            <InteractiveDropDown className = "App-input-field"
                                size = "large"
                                name = "Collection"
                                isOptional = {true}
                                value = {this.state.collection}
                                addClassification = {this.addCollection}
                                items = {this.state.collections} 
                                newValueSelected = {(value) => { this.setState({collection: value})}}
                                require/>
                        </div> 
                        <div className="label-input">
                            <Label
                                displayName = "Size (cm)"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                name = "objectSize"
                                value = {this.state.objectSize}
                                onChange = {this.handleInputChange}
                            />  
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Material"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <InteractiveDropDown className = "App-input-field"
                                size = "large"
                                name = "Material"
                                isOptional = {true}
                                value = {this.state.objectMaterial}
                                addClassification = {this.addMaterial}
                                items = {this.state.materials} 
                                newValueSelected = {(value) => { this.setState({objectMaterial: value}, () => {console.log("Material: ", value)})}}
                                require/> 
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Weight (kg)"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                isOptional = {true}
                                name = "objectWeight"
                                value = {this.state.objectWeight}
                                onChange = {this.handleInputChange}
                            />
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Date (century)"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                isOptional = {true}
                                name = "objectDate"
                                value = {this.state.objectDate}
                                onChange = {this.handleInputChange}
                            />   
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Provenance"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                isOptional = {true}
                                isMultiLine = {true}
                                name = "provenance"
                                value = {this.state.provenance}
                                onChange = {this.handleInputChange}
                            />        
                        </div> 
                        <div className="label-input">
                            <Label
                                displayName = "Analyses"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                    name = "analyses"
                                    isOptional = {true}
                                    isMultiLine = {true}
                                    value = {this.state.analyses}
                                    onChange = {this.handleInputChange}
                                />  
                        </div> 
                        <div className="label-input">
                            <Label
                                displayName = "Research"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                displayName = "Research"
                                isOptional = {true}
                                isMultiLine = {true} 
                                name = "research"
                                value = {this.state.research}
                                onChange = {this.handleInputChange}
                            />     
                        </div>  
                        <div className="label-input">
                            <Label
                                displayName = "Sources"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                isOptional = {true}
                                isMultiLine = {true}
                                name = "sources"
                                value = {this.state.sources}
                                onChange = {this.handleInputChange}
                            />     
                        </div>
                        <div className="label-input">
                            <Label
                                displayName = "Thesaurus"
                                isOptional = {true}
                            />
                        </div>         
                        <div className="text-input">
                            <EditableInput
                                isOptional = {true}
                                name = "thesaurus"
                                value = {this.state.thesaurus}
                                onChange = {this.handleInputChange}
                            />     
                        </div>           
                    </div>
                    <div className="image-input-field">
                        <div className="image-upload-grid">
                            <div className="image-upload-grid-item">
                                <ImageUpload 
                                    addUrl = {this.addUrl}
                                    addImageName = {this.addImageName}
                                    resetUrls = {() => {this.setState({urls: []})} }
                                />
                            </div>
                            <div className={this.state.urls === [] ? "Hidden" : "image-upload-grid-item"}>
                                <ImageGridView 
                                    text = "Uploaded images:" 
                                    urls = {this.state.urls}
                                    removeImage = {this.removeImage}
                                ></ImageGridView>
                            </div>
                        </div>
                    </div>
                    <Button className = "upload-button"
                            size = "large"
                            type = "primary"
                            onClick = { this.uploadObject }
                            loading = { this.state.addingObject } 
                            disabled = {
                                this.state.objectName === ""
                            }
                        >
                        Upload 
                        </Button> 
                </form>
            </div>
        )
    }

}

export {
    DataUpload
}