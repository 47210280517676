import React, { Component } from "react";

import { Input, List, Typography} from "antd";

import "./App.css";

const {TextArea} = Input
const { Text } = Typography

class EditableInput extends Component {

    render() {
        return(
            <div className = "upload-fields">
            {            
            this.props.isMultiLine ?
            <TextArea rows={4}
                name = {this.props.name}
                ref = "text-input-field"    
                className = "App-text-input-field"
                size = "large"
                onChange = {this.props.onChange}
                value = {this.props.value}
                required 
            ></TextArea>
            :
            <Input 
                name = {this.props.name}
                ref = "text-input-field"    
                className = {this.props.isOptional ? "App-optional-text-input-field" : "App-text-input-field"}
                size = "large"
                onChange = {this.props.onChange}
                value = {this.props.value}
                required 
            /> 
            }
            </div>
        )
    }
}


export {
    EditableInput
}
