import React, { Component } from "react";
import { Button, Typography } from "antd";

import {storage} from "./firestore";

const {Text} = Typography

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      progress: 0
    };
  }

  handleChange = e => {
    if (e.target.files[0]) {
      const images = e.target.files;
      if(images === []) {
        console.log("no images");
        return;
      }

      this.setState({ images: images }, () => {
        for (let i = 0; i < images.length; i++) {
          console.log("Image uploaded ", images[i].name)
          this.sendImageNameToParent(images[i].name)
        }
      });
    }
  };

  handleUpload = () => {
    const { images } = this.state;
    if (images === []) {
        console.log("Images are null")
        return;
    }

    this.props.resetUrls()

    for (let i = 0; i < images.length; i++) {

      const uploadTask = storage.ref(`images/${images[i].name}`).put(images[i]);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          const urlPromise = storage
                        .ref("images")
                        .child(images[i].name)
                        .getDownloadURL()
          const namePromise = storage
                              .ref("images")
                              .child(images[i].name)
                              .getMetadata(); 
          
            Promise.all([urlPromise, namePromise]).then( (values) => {
              const url = values[0];
              const imageName = values [1].name;

              console.log("Upload Name: ", imageName);
              this.sendUrlToParent(imageName, url)
            }); 
          }
      );
    }
  };

  sendUrlToParent = (imageName, url) => {
      this.props.addUrl(imageName, url)
  };

  sendImageNameToParent = (imageName) => {
    // this.props.addImageName(imageName)
  }

  render() {
    return (
      <div className="center">
        <br />
        <div className="image-upload-area"> 
        <Text><h4>Upload images</h4></Text>
          <Button className="App-add-image-button">
            <input type="file" multiple onChange={this.handleChange}/>
          </Button>
        </div>
        <Button className="App-image-upload-button"
                onClick={this.handleUpload}
        >
          Upload
        </Button>
        <br />
        <div className="row">
          <progress value={this.state.progress} max="100" className="progress" />
        </div>
        <br />
      </div>
    );
  }
}

export  {
    ImageUpload
}
