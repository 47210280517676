import React, {Component} from "react"
import {Image, Typography } from "antd"
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';

// import ImageGallery from 'react-image-gallery';

const { Text } = Typography

class ImageSlider extends Component {  
    constructor(props) {
      super(props);
      this.state = {
        numCols: 2
      }
    }

    render() {    
      let urls = this.props.urls;

      return (  
        <div className="image-gallery">
           <Image.PreviewGroup>
            {urls.map((img, index) => 
                <Image className="overview-image-style"
                  key={index}  
                  width={120}
                  height={100}
                  src={img}
                  hidden={index !== 0}>
              </Image>
            )}
          </Image.PreviewGroup>
        </div>
      );
    }
}

export {
    ImageSlider
}