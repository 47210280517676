import React, { Component } from "react";

import { Input, List, Typography} from "antd";

import "./App.css";

const { Text, Paragraph } = Typography
const {TextArea} = Input


class Label extends Component {


    render() {
        return(
            <Text                 
                className = "app-text-input-name"
                > {this.props.displayName}
            </Text>        
        )
    }
}



export {
    Label
}