import React, { Component } from "react";
import ReactMarkdown from 'react-markdown'

import { Input, List, Typography} from "antd";

import "./App.css";

const { Text, Paragraph } = Typography
const {TextArea} = Input


class EditableLabel extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     editedText: props.value
        // }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const editedText = target.value;
        this.props.onChange(editedText)

        // this.setState({editedText}, () => {
        //     this.props.onChange(editedText)
        //     console.log("EditedText: " + this.state.editedText)
        // });
    }

    render() {
        // const hide = this.props.hide == undefined ? true : this.props.hide;  
        return(
                <div className="row-layout-card-itemDetails">
                    {this.props.isEditing 
                    ?                     
                        this.props.isMultiLine 
                        ?    <TextArea onChange={this.handleInputChange} rows={4} defaultValue={this.props.value}> {this.props.value} </TextArea>
                        :    <Text editable={{onChange: this.props.onChange}} >{this.props.value}</Text> 
   
                    : // not editing
                        this.props.isMultiLine 
                        ?
                            <ReactMarkdown className= "Editable-Labels">{this.props.value}</ReactMarkdown> 
                        :   <Text>{this.props.value}</Text> 
                    }
            </div>
        
        )
    }
}



export {
    EditableLabel
}