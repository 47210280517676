import React, { Component } from "react";

import { DataUpload } from "./DataUpload";
import { FilteredObjects } from "./FilteredObjects";

import { firestore, app } from "./firestore";
 
import "./App.css";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
 
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            objects: [],
            isSignedIn: false, // Local signed-in state.
            isCurrentUserOwner: false
        };

        // We listen for live changes to our objects collection in Firebase
        firestore.collection("objects").onSnapshot(snapshot => {
            let objects = [];
            snapshot.forEach(doc => {
                const obj = doc.data();
                obj.id = doc.id;
                objects.push(obj);  
            });
            // Sort our objects based on time added
            objects.sort(function(a, b) {
                return (
                    // new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    // a.objectName.localeCompare(b.objectName)
                    a.collection.localeCompare(b.collection) == 0 
                    ? a.idNumber.localeCompare(b.idNumber)  
                    : a.collection.localeCompare(b.collection)
                );
            });
            // Anytime the state of our database changes, we update state
            this.setState({ objects }, () => {
                console.log("object updated")
            });
        });

       
    }

    uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          // Avoid redirects after sign-in.
          signInSuccessWithAuthResult: () => false
        }
      };
     
      // Listen to the Firebase Auth state and set the local state.
      componentDidMount() {
        let currentComponent = this;

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
                currentComponent.setState({isSignedIn: !!user})
            
                if (!user) {
                    return;
                }
                
                let userProfile = firestore.collection("roles").doc(user.uid)

                userProfile.get().then(function(doc) {
                    if (doc.exists) {
                        let role = doc.data().role
                        let isOwner = role && role === "owner";
                        console.log("IsOwner: ", isOwner)
                        currentComponent.setState({isCurrentUserOwner: !!isOwner})
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }
        );
        }        
      
      // Make sure we un-register Firebase observers when the component unmounts.
      componentWillUnmount() {
        this.unregisterAuthObserver();
      }
    
    render() {
        if (!this.state.isSignedIn) {
            return (
                <div>
                <h1>Inventory</h1>
                <p>Please sign-in:</p>
                <StyledFirebaseAuth 
                    uiConfig={this.uiConfig} 
                    firebaseAuth={firebase.auth()}/>
                </div>
            );
        }
        return (
            <div className = "app-layout" >
                <div className = "header-layout" >
    
                    <div className = "header-grid-layout">
                        <a className = "home-button" href = "http://rc-materialculture.de"><b>RCMC</b></a>
                        <div>
                            <h1> Database Benin Objects (Nigeria) </h1> 
                            <p><b>Research: Audrey Peraldi, Andreas Schlothauer</b></p>
                        </div>
                    </div>
                </div>
                <div className = "content-layout" >
                        <div className = "upload-section" hidden = {!this.state.isCurrentUserOwner}>
                            <h2> Upload Data </h2>
                            <DataUpload></DataUpload>
                            <br/><br/>
                        </div>
                        <FilteredObjects objects = {this.state.objects}/>
                </div> 
            </div> 
        );
    }
}

export default App;