import React, { Component } from "react";
import { EditableLabel } from "./EditableLabel";
import {ImageGridView} from "./ImageGridView.js"
import {ImageUpload} from "./ImageUpload"

import {Card, Button, Typography} from "antd"
import { Label } from "./Label"

import { firestore } from "./firestore";
import firebase from 'firebase';

import "./App.css";

const { Text } = Typography

class CardDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateEdit: "Edit", 
            isCurrentUserOwner: false,
            // object: props.object 
        }

        // // We listen for live changes to our objects collection in Firebase
        // firestore.collection("objects").onSnapshot(snapshot => {
        //     let object;
        //     snapshot.forEach(doc => {
        //         if (doc.id == this.props.object.id)
        //         {
        //             const obj = doc.data();
        //             obj.id = doc.id;
        //             object = obj;

        //         }
        //     });
        
            // Anytime the state of our database changes, we update state
            // this.setState({ object });
        // });
    
    }
   
    logObject = (id, key, value) => {
        console.log("Update object id: ", id)
        console.log("@Key: ", key)
        console.log("value type: ", typeof(value))
        console.log("with Value: ", value)
    }

    async asyncUpdateObject(docId, key, value) {
        this.logObject(docId, key, value)
        await firestore.collection("objects").doc(docId).update({[key]: value})
    }

    async asyncMergeKey(docId, key1, value1) {
        this.logObject(docId, key1, value1)        
        await firestore.collection("objects").doc(docId).set({
                [key1]: value1 
            }, 
            {merge: true}
        )
    }

    async asyncMergeKey(docId, key1, value1, key2, value2) {
        this.logObject(docId, key1, value1, key2, value2)        
        await firestore.collection("objects").doc(docId).set({
                [key1]: value1, 
                [key2]: value2
            }, 
            {merge: true}
        )
    }

    update = (key, value) => {
        console.log("Updatee: ", value);
        this.asyncUpdateObject(this.props.object.id, key, value)
    }

    editable = () => {
        return  this.state.stateEdit === "Done"
    }

    handleInEditChange = (e) => {
        console.log("Button clicked")
        this.setState({stateEdit: this.state.stateEdit === "Edit" ? "Done" : "Edit"}, ()=>{console.log("State: ", this.state.stateEdit)})
    }

    addUrl = (imageName, newUrl) => {
        // console.log("Urls: ", this.props.object.urls)
        let id = this.props.object.id;
       
        var urls = []
        urls.push(newUrl)
        let oldUrls = this.props.object.urls
        if (oldUrls) {
            urls = urls.concat(oldUrls)
        }

        var imageNames = []
        imageNames.push(imageName)
        let oldImageNames = this.props.object.imageNames
        if (oldImageNames) {
            imageNames = imageNames.concat(oldImageNames)
        }
        this.asyncMergeKey(id, "urls", urls, "imageNames", imageNames)
        console.log("Merged image name: ", imageNames[0]);
    }

    async removeImageAsync (index) {
        if(typeof this.props.object == 'undefined') {
            return;
        }
        let url = this.props.object.urls[index];
        let name = this.props.object.imageNames[index];
        if (typeof url == 'undefined' || typeof name == 'undefined') {
            return;
        }
        // set_urls(urls.splice(index, 1))
        // set_imageNames(names.splice(index, 1))
        // this.logObject(docId, key1, value1, key2, value2)        
        let docId = this.props.object.id;
        let urls = this.props.object.urls;
        let names = this.props.object.imageNames; 
        urls.splice(index,1);
        names.splice(index,1);

        await firestore.collection("objects").doc(docId).update({
                "urls": urls,
                "imageNames": names 
         });
    }

    removeImage = (index) => {
        this.removeImageAsync(index)
    }

    addImageName = (newName) => {
        // console.log("Imagename: ", this.props.object.imageNames)
        // let id = this.props.object.id;
        // var imageNames = []
        // imageNames.push(newName)
        // let oldImageNames = this.props.object.imageNames
        // if (oldImageNames) {
        //     imageNames = imageNames.concat(oldImageNames)
        // }
        // this.asyncMergeKey(id, "imageNames", imageNames)
    }

    componentDidMount() {
        let currentComponent = this;

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => { 
                if (!user) {
                    return;
                }
                
                let userProfile = firestore.collection("roles").doc(user.uid)

                userProfile.get().then(function(doc) {
                    if (doc.exists) {
                        let role = doc.data().role
                        let isOwner = role && role === "owner";
                        currentComponent.setState({isCurrentUserOwner: !!isOwner})
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            });
      }
      
      // Make sure we un-register Firebase observers when the component unmounts.
      componentWillUnmount() {
        this.unregisterAuthObserver();
      }

    async isOwner() {
        let uid = firebase.auth().currentUser.uid
        let user = firestore.collection("roles").doc(uid)
        return user && user.data && user.data.role && user.data.role === "owner";
    }

    render() {
        const isLoggedIn = this.state.isCurrentUserOwner;
        let button;
        let editButtonText = this.editable() ? "Done" : "Edit";
        if (isLoggedIn) {
          button = 
            <Button type="primary" size="large" onClick={this.handleInEditChange}>
                {editButtonText}
            </Button>
        } 
        let imageUploadLabel; 
        if (isLoggedIn) {
            imageUploadLabel =             
            <div className="label-input">
                <Label
                    displayName = "Image Upload"
                />
            </div> 
        }
        let imageUpload;
        if (isLoggedIn) {
            imageUpload = 
            <div className="ImageInput">
                <div className="image-upload-grid">
                <div className={this.editable() ? "image-upload-grid-item" :  "Hidden"}>
                    <ImageUpload 
                        addUrl = {this.addUrl}
                        addImageName = {this.addImageName}
                        resetUrls = {() => {this.setState({stateEdit: "Edit"})}}
                    />
                </div>                        
            </div>
           </div>    
        }
        const object = this.props.object;
        if (object) {
            console.log("Focused object >>>>>>>>>")
            console.log("ObjectName: ", object.objectName)
            
        }
        return (
        <div className="stick-right">
        {button}
        <form className="data-input-fields-gridDetails">                   
                <div className="title-Name"> 
                    <EditableLabel 
                            name = ""
                            value = {object ? object.objectName : ""}
                            onChange = {(v) => this.update("objectName", v)}
                            isEditing = {this.editable()}
                    ></EditableLabel> 
                </div>  
                <div className="label-input">
                    <Label
                        displayName = "Classification"
                    />
                </div>
                <div className="text-input"> 
                    <EditableLabel 
                            value = {this.props.object ? this.props.object.classification : ""}
                            onChange = {(v) => this.update("classification", v)}
                            isEditing = {this.editable()}
                    ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Culture"
                    />
                </div>
                <div className="text-input"> 
                    <EditableLabel 
                            value = {object ? object.culture : ""}
                            onChange = {(v) => this.update("culture", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "ID-Number"
                    />
                </div>
                <div className="text-input"> 
                <   EditableLabel 
                            value = {object ? object.idNumber : ""}
                            onChange = {(v) => this.update("idNumber", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel>  
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Collection"
                    />
                </div>
                <div className="text-input"> 
                    <EditableLabel 
                            value = {object ? object.collection : ""}
                            onChange = {(v) => this.update("collection", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel>  
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Size (cm)"
                    />
                </div>
                <div className="text-input"> 
                    <EditableLabel 
                            value = {object ? object.objectSize : ""}
                            onChange = {(v) => this.update("objectSize", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel>  
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Weight (kg)"
                    />
                </div>
                <div className="text-input"> 
                     <EditableLabel 
                            value = {object ? object.objectWeight : ""}
                            onChange = {(v) => this.update("objectWeight", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel>  
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Material"
                    />
                </div>
                <div className="text-input"> 
                        <EditableLabel 
                            value = {object ? object.objectMaterial : ""}
                            onChange = {(v) => this.update("objectMaterial", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Date (century)"
                    />
                </div>
                <div className="text-input"> 
                     <EditableLabel 
                            value = {object ? object.objectDate : ""}
                            onChange = {(v) => this.update("objectDate", v)}
                            isEditing = {this.editable()}
                        ></EditableLabel>   
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Provenance"
                    />
                </div>
                <div className="text-input"> 
                        <EditableLabel 
                            value = {object ? object.provenance : ""}
                            onChange = {(v) => this.update("provenance", v)}
                            isEditing = {this.editable()}
                            isMultiLine = {true}
                        ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Research"
                    />
                </div>
                <div className="text-input"> 
                        <EditableLabel 
                            value = {object ? object.research : ""}
                            onChange = {(v) => this.update("research", v)}
                            isEditing = {this.editable()}
                            isMultiLine = {true}
                        ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Analyses"
                    />
                </div>
                <div className="text-input"> 
                        <EditableLabel 
                            value = {object ? object.analyses : ""}
                            onChange = {(v) => this.update("analyses", v)}
                            isEditing = {this.editable()}
                            isMultiLine = {true}
                        ></EditableLabel> 
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Sources"
                    />
                </div>
                <div className="text-input"> 
                        <EditableLabel 
                            value = {object ? object.sources : ""}
                            onChange = {(v) => this.update("sources", v)}
                            isEditing = {this.editable()}
                            isMultiLine = {true}
                        ></EditableLabel>
                </div> 
                <div className="label-input">
                    <Label
                        displayName = "Images"
                    />
                </div>
                <div className={object && object.urls === [] ? "Hidden" : ""}>
                            <ImageGridView 
                                text = "" 
                                urls = {object ? object.urls : []} 
                                imageNames = {object ? object.imageNames : []} 
                                removeImage = {this.removeImage}
                                isLoggedIn = {isLoggedIn}>
                           </ImageGridView>
                </div>
                {imageUploadLabel}
                {imageUpload}

            {/* <div className="imageArea">
            <div className={this.props.object.urls === [] ? "Hidden" : "image-upload-grid-item"}>
                <ImageGridView text = "" urls = {this.props.object.urls}></ImageGridView>
            </div> */}
                {/* <img
                    src={this.props.object.url}
                    alt="Database Benin Objects (Nigeria)"
                    height="400"
                    width="400"
                /> */}
            {/* </div> */}
            {/* </Card> */}

        {/* </div>    */}
        </form>
        </div>    
           // <div className="data-upload-grid">
            //     <div className="text-input">
            //         <EditableLabel 
            //             name = "Object"
            //             value = {this.props.object.objectName}
            //         ></EditableLabel>
            //         <br></br>
            //     </div>
            //     <div className="ImageInput">
            //         <div className={this.props.object.url === "" ? "Hidden" : ""}>
            //             <img
            //                 src={this.props.object.url}
            //                 alt="Database Benin Objects (Nigeria)"
            //                 height="400"
            //                 width="400"
            //             />
            //         </div>
            //     </div>
            // </div>
        
        
        )
    }
}

export {
    CardDetails
}