import React, { useState } from "react";
import {ImageSlider} from "./ImageSlider.js"

import {Card, Button, Typography} from "antd"

import { firestore } from "./firestore";
import firebase from 'firebase';

import "./App.css";

const { Text } = Typography

function CardOverview(props) {

    return (
        <div className={props.focused ? "cardOverview-layout-border" : "cardOverview-layout"}
            onClick={props.onClicked}
        >
                <Button 
                    type="link"
                    className = "card-item-title" onClick={props.onClicked}>
                        {props.object.objectName}
                </Button> 

                <ImageSlider urls = {props.object.urls}></ImageSlider>

        </div>
    )

}

export {
    CardOverview
}