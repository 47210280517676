import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";

var config = {
    apiKey: "AIzaSyAZsaos-_d6hcxUbGn8SuPsT-dqwGfTo_Q",
    authDomain: "benin-f8846.firebaseapp.com",
    databaseURL: "https://benin-f8846.firebaseio.com",
    projectId: "benin-f8846",
    storageBucket: "benin-f8846.appspot.com",
    messagingSenderId: "305630550470",
    appId: "1:305630550470:web:5b057da2b3baad679e3ac7",
    measurementId: "G-Y2381ZQGQG"
  };

const app = firebase.initializeApp(config);
const firestore = firebase.firestore(app);
const storage = firebase.storage(app);

export {
    app, firestore, storage
}