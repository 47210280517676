import React, {Component}  from "react"

import { Select, Divider, Input, Typography } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import { render } from "node-sass";

import { firestore } from "./firestore";

const { Option } = Select;
const { Text } = Typography

class SearchDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: [], 
    }
  }
  componentDidMount() {
    firestore.collection("collections").onSnapshot(snapshot => {
      let collections = [];
      snapshot.forEach(doc => {            
          const obj = doc.data();
          obj.id = doc.id;
          let collection = obj.collection;
          // console.log("collection: ", obj.collections)
          collections.push(collection);  
      
          collections.sort(function(a, b) {
              return ( a.localeCompare(b) );
          });
      })

      this.setState({ collections })
    });
  }

  onValueChanged = (v) => {
    this.props.onValue(v)
  }

  render() {
    return (
        <div>
          <Select 
            onChange = { this.onValueChanged }
            value = {this.props.value}
            style={{ width: 240 }}
            size = "large"
            placeholder = "Choose collection"
            dropdownRender={menu => (
              <div className="App-input-field">
                {menu}
                <Divider style={{ margin: '4px 0' }} />
              </div>
            )}
          >
          {this.state.collections.map(item => (
            <Option key={item}>{item} </Option>
          ))}
          </Select>
        </div>
    )
  }
}


export {
    SearchDropDown
}