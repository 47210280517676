import React, {Component} from "react"
import {Button, Image, Row, Col, Typography } from "antd"
import {storage} from "./firestore"

const { Text } = Typography

class ImageGridView extends Component {  
    constructor(props) {
      super(props);
      this.state = {
        numCols: 2
      }
    }
  
    displayImage = (row, col) => {
      let index = row*this.state.numCols + col;
      let button;
      if (this.props.isLoggedIn) {
        button = <Button type="primary" size="large" onClick={() => this.removeImg(index)}>X</Button>
      }
      if (index < this.props.urls.length) {
        return (
          <div>
            <Image className="overview-image-style"
              key={index}  
              width={200}
              height={200}
              src={this.props.urls[index]}
            >
            </Image>
            {/* <img
              src={this.props.urls[index]}
              alt="Uploaded Images"
              height="200"
              width="200"
            />  */}
            {button}
        </div>
          
        )
      }
      else {
        return (null)
      }

    }

    removeImg = (index) => {
      const urls = this.props.urls;
      if(typeof urls == 'undefined' || urls.length === 0) {
        return;
      }

      const imageNames = this.props.imageNames;
      if (typeof imageNames == 'undefined' || imageNames.length === 0) {
        return;
      }

      const url = this.props.urls[index];
      const name = this.props.imageNames[index];

      console.log("Remove image: ", url)
      console.log("Remove with name: ", name)

      storage.ref(`images/${name}`).delete();
      this.props.removeImage(index)
      }
  
    computeCols = (row) => {
      let cols = []
      let numCols = this.state.numCols;

      for (let col = 0; col < numCols; col++) {
        cols.push(
            <Col key={col}>
              {this.displayImage(row, col)}
            </Col>
         ); 
      }

      return cols;
    }

    computeGrid = () => {
      if (!this.props.urls) return (null);

      const numUrls = this.props.urls.length;
      let numRows = Math.max(1, Math.ceil(numUrls / this.state.numCols));
      let grid = [];

      for (let row = 0; row < numRows; row++) {
        grid.push(
          <Row gutter={[8,8]} key={row}>
            {this.computeCols(row, numRows)}
          </Row>);
      }

      return grid
    }

    render() {
      return (  
        <div className="image-upload-grid">
          <div className="image-upload-grid-item">
            <Text>{this.props.text}</Text>
          </div>
          <div className="image-upload-grid-item">
          <Image.PreviewGroup>
            {this.computeGrid()}
            </Image.PreviewGroup>
          </div>
        </div>
      );
    }
}

export {
    ImageGridView
}