import React, {Component}  from "react"

import { Select, Divider, Input, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

  
const { Option } = Select;
const { Text } = Typography
let index = 0;

class InteractiveDropDown extends Component {

  state = {
    items: [],
    name: '',
  };

  onNameChange = event => {
    this.setState({name: event.target.value});
  };


  addItem = () => {
    console.log('addItem');    
    const { items, name } = this.state;
    console.log('name, ', name)
    this.props.addClassification(name)
    this.setState({
      items: [...items, name || `New item ${index++}`],
      name: '',
    });
  };

//   labelText = (name, isOptional) => {
//     let addonBefore = name + " "
    
//     if (isOptional) {
//         addonBefore = addonBefore + " - Optional"    
//     }

//     return addonBefore
// }

  render() {
    const { name } = this.state;
    return (
      <div>
      <Select 
        onChange={this.props.newValueSelected}
        value = {this.props.value}
        style={{ width: 240 }}
        placeholder = {this.props.name}
        dropdownRender={menu => (
          <div className="App-input-field">
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
              <a
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={this.addItem}
              >
                <PlusOutlined /> Add item
              </a>
            </div>
          </div>
        )}
      >
        {this.props.items.map(item => (
          <Option key={item}>{item} </Option>
        ))}
      </Select>
      </div>
    );
  }
}

export {
    InteractiveDropDown
}