import React, { useState, useEffect} from "react";

import { Typography, Input, List } from "antd";

import "./App.css";
import { CardOverview } from "./CardOverview";
import { CardDetails } from "./CardDetails"

import { SearchDropDown } from "./SearchDropDown";

const { Text } = Typography
const { Search } = Input

function FilteredObjects(props) {
    
    const [searchValue, setSearchValue] = useState("");
    const [objectInputFilter, setObjectInputFilter] = useState("");
    const [showDetails, setShowDetails] = useState(null);
    const [filteredCollection, setFilteredCollection] = useState(null);

    useEffect(() => {
        if (showDetails == null) {
            if(props.objects && props.objects.length > 0) {
                setShowDetails(props.objects[0])
            }
        }
    });

    function filter (objectFilter) {
        let filteredObjects = props.objects
        filteredObjects = filteredObjects.filter((object => {
            if (objectFilter === "") {
                return true;
            }

            let collection = object.collection.toLowerCase();
            let index = collection.indexOf(objectFilter.toLowerCase());

            if (index  !== -1) {
                return true;
            }
            
            let name = object.objectName.toLowerCase();
            return name.indexOf(objectFilter.toLowerCase()) !== -1;
        }))
        return filteredObjects
    }

    function focusedObject (obj) {
        if (obj == null) {
            return;
        }
        let focused = props.objects.filter((object => {
            return object.id === obj.id
        }));

        if (focused.length > 0) {
            return focused[0];
        }

        return null;
    }

    return (
        <div className="search-section">
            <div className="search-input">
                <div className="search-title">
                    <Text ><h3>Search</h3></Text>
                </div>
                <Input.Group compact>
                <Search  
                    className = "app-text-input-field" 
                    size = "large"
                    onChange = { evt =>  {
                        setSearchValue(evt.target.value);
                        setFilteredCollection(null); 
                    } 
                    }
                    onSearch = {() => {
                        setObjectInputFilter(searchValue);
                        setShowDetails(filter(objectInputFilter)[0]);  
                    }}
                    enterButton
                    placeholder = "Search"
                    value = { searchValue }
                    required 
                />
                <SearchDropDown 
                    onValue = { v => {
                        setFilteredCollection(v);
                        setObjectInputFilter(v);
                        setSearchValue(v);
                        setShowDetails(filter(v)[0]);  
                    }}
                    value = {filteredCollection}
                ></SearchDropDown>
                </Input.Group>
            </div>
        <div className="leftRightSplit">
            <div className="itemLeftScroll">
                <List className = "app-objects"
                    grid={{
                        gutter: 12
                    }}
                    size = "large"
                    dataSource = {filter(objectInputFilter)}
                    renderItem = {
                        obj => ( 
                            <List.Item>
                                <CardOverview 
                                    focused = {focusedObject(showDetails) === obj}
                                    object = {obj}
                                    onClicked = {() => { setShowDetails(obj) }}>
                                </CardOverview>
                            </List.Item>
                        )
                    } 
                />
            </div>
            <div className="itemRightDetails">
                    <CardDetails object = { focusedObject(showDetails) }></CardDetails>    
            </div>
        </div> 
    </div>   
    )
}
export {
     FilteredObjects
}
